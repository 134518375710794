import React, { useContext } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import { AppBar, Box, Button, Grid, Toolbar, Typography } from '@mui/material';

import { AuthContext } from '../../context/authContext';
import { Link, useNavigate } from 'react-router';
// import { IS_LOGGED_IN } from '../../store/typeDefs';


function MainNavBar() {
    const client = useApolloClient();
    const { user, logout } = useContext(AuthContext)
    let navigate = useNavigate();

    const onLogout = () => {
        logout();
        navigate('/login')
        // client.writeQuery({ query: IS_LOGGED_IN, data: { isLoggedIn: false } })
    }

    return (
        <Box>
            <AppBar position='static'>
                <Toolbar>
                    <Grid container spacing={2} >
                        <Grid item xl={2} container direction="row" justifyContent="flex-start" alignItems="center">
                            <Typography variant='h5' component="div">
                                <Link to="/" style={{ textDecoration: "none", color: "white" }}>SMS</Link>
                            </Typography>
                        </Grid>

                        <Grid item xl={8} container direction="row" justifyContent="center" alignItems="center">
                            {/* {user ? <>
                                <Link to="/checks" style={{ textDecoration: "none", color: "white", marginRight: "10px" }}>Чеки</Link>
                                <Link to="/category" style={{ textDecoration: "none", color: "white", marginRight: "10px" }}>Категории</Link>
                                <Link to="/operation" style={{ textDecoration: "none", color: "white", marginRight: "10px" }}>Операции</Link>
                                <Link to="/currency" style={{ textDecoration: "none", color: "white", marginRight: "10px" }}>Валюта</Link>
                                <Link to="/setting" style={{ textDecoration: "none", color: "white", marginRight: "10px" }}>Настройки</Link>
                                <Link to="/report" style={{ textDecoration: "none", color: "white", marginRight: "10px" }}>Отчеты</Link>
                            </>
                                : null} */}
                        </Grid>

                        <Grid item xl={2} container direction="row" justifyContent="flex-end" alignItems="center">
                            {user
                                ? <Button style={{ textDecoration: "none", color: "white" }} onClick={onLogout}>Logout</Button>
                                : <>
                                    <Link to="/login" style={{ textDecoration: "none", color: "white", marginRight: "10px" }}>Login</Link>
                                    <Link to="/register" style={{ textDecoration: "none", color: "white" }}>Register</Link>
                                </>}
                        </Grid>

                    </Grid>

                </Toolbar>
            </AppBar>
        </Box>
    )


}

export default MainNavBar;