import React from 'react';
import { useMutation } from '@apollo/client';

import { Delete } from '../../../components/actions/delete';

import { DELETE_CONTRACT, GET_CONTRACTS } from '../../../apollo/gql/billing/Contract';


const DeleteContract = ({ id }) => {

  const [deleteUser] = useMutation(DELETE_CONTRACT, {
    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: GET_CONTRACTS }]
  });

  const onDelete = (e) => {
    e.preventDefault();
    deleteUser();
  };

  return (

    <Delete formSubmit={onDelete} />

  )


};


export default DeleteContract;