import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';

import { AuthContext } from '../context/authContext';

import Loader from '../components/main/Loader';

import { GET_ME } from '../apollo/gql/administration/User';


export const useCurrentUser = () => { 
    // console.log('useCurrentUser', useCurrentUser)
    const { user } = useContext(AuthContext);

    const { loading, error, data } = useQuery(GET_ME, {
        variables: { id: user?._id || user?.user_id },
        fetchPolicy: "network-only"
    });

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const currentUser = data.getMe;
    
    return { currentUser }

};