import { Box, Tooltip, Typography, withStyles } from '@mui/material';
import React from 'react';
// import { Box, Tooltip, Typography } from '@material-ui/core';
// import { withStyles } from '@material-ui/core/styles';


// const LightTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: theme.palette.common.white,
//     color: 'rgba(0, 0, 0, 0.87)',
//     boxShadow: theme.shadows[1],
//     fontSize: 11,
//   },
// }))(Tooltip);

const OpenDataId = ({ label, data, color, title }) => {

  return (
    <Tooltip
      style={{ maxWidth: 500 }}
      placement="left"
      title={!title ? null : title}
      arrow>
      <Box display="flex" alignItems="center">
        <Box p={1}>
          <Typography variant="caption">{label}:  </Typography>
        </Box>
        <Box p={1}>
          <Typography variant="body2" style={{ background: `${color}` }}>
            {data}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );


};


export default OpenDataId;