import React from 'react';

import { CircularProgress } from '@mui/material';
import { green } from '@mui/material/colors';

const Loader = ({size}) => {

  return (
    <CircularProgress style={{ color: green[500] }} size={!size ? 15 : size}/>
  );


};

export default Loader;