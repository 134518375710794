import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { red } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';


export const Delete = ({ fontSize, formSubmit }) => {

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Delete"
        arrow>
        <IconButton aria-label="delete"
          type="submit"
          onClick={() => setOpen(true)}>
          <DeleteIcon style={{ color: red[800] }}
            fontSize={fontSize} />
        </IconButton>
      </Tooltip>

      <Dialog open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">

        <form onSubmit={formSubmit}>
          <DialogTitle id="form-dialog-title">
            Вы действительно хотите удалить запись?
          </DialogTitle>

          <DialogActions>
            <Button type="submit"
              onClick={handleClose}
              color="primary">
              Удалить
            </Button>
            <Button onClick={handleClose}
              color="primary">
              Отмена
            </Button>

          </DialogActions>
        </form>
      </Dialog>
    </>


  );


};