import React, { useContext, useEffect, useState } from 'react';

import { useTelegram } from '../../hooks/useTelegram.hook';

import { AUTH_VIA_TELEGRAM } from '../../apollo/gql/contact/Telegram';
import { AuthContext } from '../../context/authContext';
import { useForm } from '../../hooks/useForm.hook';
import { useMutation } from '@apollo/client';


export const WebAppPage = () => {

  const { tg, user } = useTelegram();

  const items = { token: false, webApp: 'true' }

  localStorage.setItem('userData', JSON.stringify(items));

  const onClose = (e) => {
    tg.close()
    localStorage.clear()
  }

  const context = useContext(AuthContext);
  const [errors, setErrors] = useState([]);

  function authViaTelegramCallback() {
    authViaTelegram();
  };
  useEffect(() => {
    tg.ready();
    console.log('tg', tg)
    console.log('user', user)
    authViaTelegramCallback()
  }, [tg])
  const { onChange, onSubmit, values } = useForm(authViaTelegramCallback, {
    chatId: `${user?.id}`
    // chatId: '216269507'
  });

  const [authViaTelegram, { loading, error, data }] = useMutation(AUTH_VIA_TELEGRAM, {
    update(proxy, { data: { authViaTelegram: userData } }) {
      console.log('tuserData', userData)
      context.login(userData);
    },
    onError({ graphQLErrors }) {
      setErrors(graphQLErrors)
    },
    variables: { ...values }
  });

  if (loading) return 'Load,,,';
  if (error) return `Error ${error}`;

  return (
    <>
      WebAppPage
      {data && data.authViaTelegram._id}
    </>
  );


};