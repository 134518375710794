import React from "react";

import Contracts from "../requests/billing/contract/Contracts";


function ContractsPage({ context, currentUser }) {

    return (
        <Contracts context={context} currentUser={currentUser}/>
    )

};

export default ContractsPage;