import React from 'react';

import { FormControl, InputLabel, MenuItem, Select, TableCell, TextField, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';


const ServicesData = ({ values, handlerChange, data, index }) => {

    return (
        <>
            <TableCell size='small'>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Услуга</InputLabel>
                    <Select size='small'
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.service}
                        label="service"
                        name="service"
                        onChange={(e) => handlerChange(index, { service: e.target.value })}
                    >
                        {data?.map(m => {
                            return (
                                <MenuItem value={m._id}>
                                <Typography variant='caption'>{m.name}</Typography>
                                
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </TableCell>

            <TableCell size='small'>
                <NumericFormat size='small' sx={{width: 60}}
                    name="amount"
                    value={values.amount}
                    onChange={(e) => handlerChange(index, { amount: e.target.value })}
                    customInput={TextField}
                    thousandSeparator
                    valueIsNumericString
                    prefix=""
                    variant="outlined"
                    label="Количество"
                />
            </TableCell>

            <TableCell size='small'>
                <NumericFormat size='small' sx={{width: 90}}
                    name="price"
                    value={values.price}
                    onChange={(e) => handlerChange(index, { price: e.target.value })}
                    customInput={TextField}
                    thousandSeparator
                    valueIsNumericString
                    prefix=""
                    variant="outlined"
                    label="Цена"
                />
            </TableCell>

            <TableCell size='small'>
                <NumericFormat size='small' sx={{width: 100}}
                    name="total"
                    value={values.total}
                    onChange={(e) => handlerChange(index, { total: e.target.value })}
                    customInput={TextField}
                    thousandSeparator
                    valueIsNumericString
                    prefix=""
                    variant="outlined"
                    label="Сумма"
                />
            </TableCell>

            <TableCell size='small'>
                <TextField fullWidth size='small' sx={{width: 100}}
                    id="outlined-multiline-static"
                    label="Информация"
                    name={"info"}
                    value={values.info}
                    onChange={(e) => handlerChange(index, { info: e.target.value })}
                    multiline
                    rows={1}
                />
            </TableCell>

        </>
    )

};


export default ServicesData;