import { gql } from "@apollo/client";


export const GET_CONTRACTS = gql` 
    query getContracts { 
        getContracts {
            _id,
            number,
            client {
                lastName,
                contacts {
                    phoneNumber
                }
            },
            createdAt,
            updatedAt
        }
    }
`;

export const GET_CONTRACT = gql`
    query getContract($id: ID) {
        getContract(_id: $id) {
            _id,
            number,
            client {
                lastName
            },
            organization {
                name
            },
            status {
                name
            },
            placeOfWork {
                address {
                    address
                }
                info
            },
            services {
                service {
                    name
                },
                amount,
                price,
                total,
                info
            },
            isReportReceived,
            isPaid,
            createdAt,
            updatedAt
        }
    }
`;

export const GET_DATA_FOR_CREATE_CONTRACT = gql` 
    query getDataForCreateContract($organization: ID) { 
        getDataForCreateContract(organization: $organization) {
            services {
                _id,
                name,
                price,
                isFixed
            },
            executors {
                _id,
                lastName
            }
        }
    }
`;

export const CREATE_CONTRACT = gql`
    mutation createContract($input: InputCreateContract) {
        createContract(input: $input) {
            _id
        }
    }
`;

export const UPDATE_CONTRACT = gql`
    mutation updateContract($id: ID) {
        updateContract(_id: $id) {
            _id
        }
    }
`;

export const DELETE_CONTRACT = gql`
    mutation deleteContract($id: ID) {
        deleteContract(_id: $id) {
            _id
        }
    }
`;