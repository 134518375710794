import { gql } from "@apollo/client";


export const GET_USERS = gql` 
  {
    getUsers{
      _id,
      firstName,
      lastName,
      middleName,
      createdAt,
      updatedAt
    }
  } 
`;

export const GET_USER = gql`
  query getUser($id: ID) {
    getUser(_id: $id) {
      _id,
      login
      userRole_id {
        _id,
        name
      },
      firstName,
      lastName,
      middleName,
      isActive,
      contacts {
        email,
        phoneNumber,
        telegram {
          _id
        }
      },
      domain {
        organization {
          _id,
          name
        }
        department {
          _id,
          name
        }
        position {
          _id,
          name
        }
      },
      createdAt,
      updatedAt
    }
  }
`;

export const GET_ME = gql`
  query getMe($id: ID) {
    getMe(_id: $id) {
      userRole_id {
        _id,
        name
      },
      isActive,
      domain {
        organization {
          _id,
          name
        }
      }
    }
  }
`;

export const GET_DATA_FOR_CREATE = gql`
  query getDataForCreate($organization: ID) {
    getDataForCreate(organization: $organization) {
      organizations {
        _id,
        name
      }
      departments {
        _id,
        name
      }
      positions {
        _id,
        name
      }
    }
  }
`;

export const REGISTER = gql`
  mutation register($input: InputRegister) {
    register(input: $input) {
      _id
    }
  }
`;

export const LOGIN = gql`
  mutation login($login: String, $password: String){
    login(login: $login, password: $password) {
      _id,  
      token,
      organization
    }      
  }
`;

export const CREATE_USER = gql`
  mutation createUser($input: InputCreateUser){
    createUser(input: $input) {
      _id
    }      
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID, $input: InputUser) {
    updateUser(_id: $id, input: $input) {
      _id
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID) {
    deleteUser(_id: $id) {
      _id
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation passwordUpdate( $password: String ) {
    passwordUpdate( password: $password) {
      _id
    }
  }
`;