import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { Grid2 } from '@mui/material';

import OpenDataId from '../../../components/view/openDataId';
import OpenLazyQuery from '../../../components/actions/openLazyQuery';

import { GET_CONTRACT } from '../../../apollo/gql/billing/Contract';
import SystemInfo from '../../../components/view/systemInfo';
import UpdateContract from './updateContract';


const GetContract = ({ id }) => {

  const [state, setState] = useState({ openModal: false });
  const onChange = () => {
    setState({ openModal: !state.openModal });
  };

  const [getData, { called, error, data }] = useLazyQuery(GET_CONTRACT, {
    variables: { id }
  });

  if (error) return `Error ${error}`;
  if (!called && state.openModal) { getData(); }

  return (
    <>
      <OpenLazyQuery
        title={"Просмотр"}
        tButtonIcon={false}
        tButtonText="Просмотр"
        dialogTitle={`Детализация`}
        icon={false}
        fullWidth={false}
        fullScreen={false}
        maxWidth={'false'}
        dialogActions={false}
        onChange={onChange}
        state={state.openModal}
        headActions={data && <UpdateContract data={data?.getContract} />}
        Fields={<>
          <Grid2 item xl={12} lg={12} md={12} sm={12} xs={12} container spacing={3} direction="row" justifyContent="space-between" alignItems="flex-start">

            <Grid2 item xl={6} lg={6} md={6} sm={6} xs={6} container direction="column" justifyContent="flex-start" alignItems="flex-start">
            {/* status,
            isReportReceived,
            isPaid, */}
              <OpenDataId
                label={"ФИО клиента"}
                data={`${data?.getContract?.client?.lastName} ${data?.getContract?.client?.firstName} ${data?.getContract?.client?.middleName}`}
              />
              <OpenDataId
                label={"number"}
                data={data?.getContract?.number}
              />
              <OpenDataId
                label={"organization"}
                data={data?.getContract?.organization?.name}
              />
              <OpenDataId
                label={"telegramId"}
                data={data?.getContract?.placeOfWork?.address?.address}
              />
            </Grid2>

            <Grid2 item xl={6} lg={6} md={6} sm={6} xs={6} container direction="column" justifyContent="flex-start" alignItems="flex-start">
              {data?.getContract?.services.map(m => {
                return (
                  <OpenDataId
                    label={"service"}
                    data={m?.service?.name}
                  />
                )
              })}
            </Grid2>

            <Grid2 item xl={12} lg={12} md={12} sm={12} xs={12} container direction="column" justifyContent="flex-start" alignItems="flex-start">
              <SystemInfo data={data?.getContract} />
            </Grid2>

          </Grid2>

        </>}
      />
    </>
  )


};


export default GetContract;