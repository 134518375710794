import { jwtDecode } from 'jwt-decode';
import React, { useReducer, createContext } from 'react';

// import jwtDecode from 'jwt-decode';


const initialState = {
    user: null,
    token: null,
    organization: null,
    isAuthenticated: false,
};

if (localStorage.getItem("token")) {
    const decodedToken = jwtDecode(localStorage.getItem("token"));
    if (decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem("token");
        initialState.user = null;
        initialState.token = null;
        initialState.organization= null;
        initialState.isAuthenticated= false;
    } else {
        initialState.user = decodedToken;
        initialState.token = localStorage.getItem("token");
    }
}

const AuthContext = createContext({
    user: null,
    login: (userData) => { },
    logout: () => { },
    token: null,
    organization: null,
    isAuthenticated: null
});

function authReduser(state, action) {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                user: action.payload,
                token: action.payload.token,
                organization: action.payload.organization,
                isAuthenticated: true
            }
        case 'LOGOUT':
            return {
                ...state,
                user: null,
                token: null,
                organization: null,
                isAuthenticated: false
            }
        default:
            return state;
    };
};

function AuthProvider(props) {
    const [state, dispatch] = useReducer(authReduser, initialState);

    const login = (userData) => {
        localStorage.setItem("token", userData.token);
        dispatch({
            type: 'LOGIN',
            payload: {
                ...userData,
                token: userData.token,
                organization: userData.organization}
        });
    };

    const logout = (userData) => {
        localStorage.removeItem("token");
        dispatch({ type: 'LOGOUT' });
    };

    return (
        <AuthContext.Provider
            value={{
                login,
                logout,
                user: state.user,
                token: state.token,
                organization: state.organization,
                isAuthenticated: state.isAuthenticated
            }}
            {...props}
        />
    )
};


export { AuthContext, AuthProvider };