import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';


const httpLink = createHttpLink({
    // uri: "http://srv.gslib.ru:8841/graphql",
    uri: "/graphql",
});

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: localStorage.getItem("token") || ""
        }
    }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            switch (err.extensions.code) {
                case 'UNAUTHENTICATED':
                    console.log("UNAUTHENTICATED USER")
                    localStorage.clear()
                    window.location.href = '/login';
            }
        }
    }
    if (networkError) {
        console.log(`[Network error]: ${networkError.message}`);
    }
});

const cache = new InMemoryCache({});

const client = new ApolloClient({
    link: from([
        errorLink,
        authLink.concat(httpLink),
        httpLink]),
    cache: cache
})

export default client;