import React from 'react';

import { Card, Grid2, Typography } from '@mui/material';

import GetContract from './getContract';
import DeleteContract from './deleteContract';


const GetContracts = ({ context, data }) => {

    return (
        <>
            {data.map((item, index) => {
                return (
                    <Card sx={{ width: '100%' }}>
                        <Grid2 container direction="row" sx={{ justifyContent: "space-between", alignItems: "baseline", padding: 1 }}>
                            <Grid2>
                                <Typography variant='caption'>{item?.number}</Typography>
                            </Grid2>
                            <Grid2>
                                <GetContract id={item?._id} />
                                <DeleteContract id={item?._id} />
                            </Grid2>
                        </Grid2>
                    </Card>
                )
            })}
        </>
    )

};


export default GetContracts;