import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { Grid } from '@mui/material';

import Update from '../../../components/actions/update';

import { GET_CONTRACTS, UPDATE_CONTRACT } from '../../../apollo/gql/billing/Contract';
// import PersonalData from './forms/personalData';


const UpdateContract = ({ data }) => {

    const [values, setValues] = useState({
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName
    });
    const handlerChangeValues = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    };

    const [updateUser] = useMutation(UPDATE_CONTRACT, {
        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: { id: data._id, input: values },
        fetchPolicy: "no-cache",
        refetchQueries: [{ query: GET_CONTRACTS }]
    });

    const formSubmit = (e) => {
        e.preventDefault();
        updateUser();
    };

    return (
        <>
            <Update
                title={"Изменение"}
                tButtonIcon={false}
                tButtonText={"Изменить"}
                fontSize="small"
                fullScreen={false}
                fullWidth={false}
                maxWidth={'false'}
                formSubmit={formSubmit}
                dialogTitle={"Изменение"}
                icon={null}
                dialogActions={true}
                Fields={<>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container spacing={3} direction="row" justifyContent="space-between" alignItems="flex-start">

                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6} container direction="column" justifyContent="flex-start" alignItems="flex-start">
                            {/* <PersonalData values={values} handlerChange={handlerChangeValues} /> */}
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6} container direction="column" justifyContent="flex-start" alignItems="flex-start">
                            {/* {data && <DomainsData values={domains} handlerChange={handlerChangeDomains} data={data.getDataForCreate} />} */}
                        </Grid>

                    </Grid>
                </>}
            />
        </>
    )


};


export default UpdateContract;