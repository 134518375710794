import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import Draggable from 'react-draggable';
import { NumericFormat } from 'react-number-format';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid2, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import { CREATE_CONTRACT, GET_CONTRACTS, GET_DATA_FOR_CREATE_CONTRACT } from '../../../apollo/gql/billing/Contract';
import ServicesData from './forms/servicesData';
import TableData from '../../../components/view/tableData';
import { red } from '@mui/material/colors';


function PaperComponent(props) {
    const nodeRef = React.useRef(null);
    return (
        <Draggable
            nodeRef={nodeRef}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} ref={nodeRef} />
        </Draggable>
    );
}

const CreateContract = ({ context, currentUser }) => {

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const [values, setValues] = useState({
        client: '67b63cbcd55b2e8e54743344',
        executor: null,
        coordinator: currentUser?._id,
        organization: currentUser?.domain?.organization?._id
    });
    const handlerChangeValues = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const [placeOfWork, setPlaceOfWork] = useState({
        address: null, info: ''
    });
    const handlerChangePlaceOfWork = (e) => {
        setPlaceOfWork({
            ...placeOfWork,
            [e.target.name]: e.target.value,
        });
    };

    const [services, setServices] = useState([{
        service: null,
        amount: 0,
        price: 0,
        total: 0,
        info: ''
    }]);
    const handlerChangeServices = (index, updatedValues) => {
        setServices(
            services.map((item, i) =>
                i === index
                    ? { ...item, ...updatedValues }
                    : item
            )
        );
        console.log('services', services)
    };
    // Функция для добавления нового элемента в массив
    const handlerAddService = () => {
        // Добавляем новый элемент в конец массива
        setServices([...services, {
            service: null,
            amount: 0,
            price: 0,
            total: 0,
            info: ''
        }]);
    };
    // Функция для удаления элемента по его ID
    const handlerRemoveService = (id) => {
        setServices(services.filter(item => item.service !== id));
    };

    const [AddUser] = useMutation(CREATE_CONTRACT, {
        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        onCompleted: (data) => {
            setOpen(false)
        },
        variables: {
            input: {
                ...values, placeOfWork: {...placeOfWork},
                services: services.map(m => {
                    return {
                        ...m, 
                        amount: parseInt(m.amount),
                        price: parseInt(m.price),
                        total: parseInt(m.total)
                    }
                })
                // [...services, amount: services.amount ]
            }
        },
        refetchQueries: [{ query: GET_CONTRACTS }]
    });

    const [getData, { called, error, data: data_GET_DATA_FOR_CREATE_CONTRACT }] = useLazyQuery(GET_DATA_FOR_CREATE_CONTRACT, {
        variables: {
            organization: values.organization
        }
    });

    if (error) return `Error ${error}`;
    if (!called && open) { getData() }

    const formSubmit = (e) => {
        e.preventDefault();
        AddUser();
        setValues({});
    };
    console.log('services', services)
    let data = []
    return (
        <>
            <Tooltip title={"Создание контракта"} arrow>
                <IconButton onClick={() => setOpen(true)}>
                    <AddCircleIcon color='primary' fontSize={"small"} />
                </IconButton>
            </Tooltip>

            <Dialog
                PaperComponent={PaperComponent}
                fullScreen={false}
                fullWidth={false}
                maxWidth={'1000'}
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
            >

                <form onSubmit={formSubmit}>

                    <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }} >
                        Создание контракта
                    </DialogTitle>

                    <DialogContent>
                        <>
                            <Stack
                                direction="column"
                                spacing={2}
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Клиент</InputLabel>
                                    <Select size='small'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={values.client}
                                        label="client"
                                        name="client"
                                        onChange={handlerChangeValues}
                                    >
                                        {data?.organizations?.map(m => {
                                            return (
                                                <MenuItem value={m._id}>{m.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Исполнитель</InputLabel>
                                    <Select size='small'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={values.executor}
                                        label="executor"
                                        name="executor"
                                        onChange={handlerChangeValues}
                                    >
                                        {data_GET_DATA_FOR_CREATE_CONTRACT?.getDataForCreateContract?.executors?.map(m => {
                                            return (
                                                <MenuItem value={m._id}>{m.lastName}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack
                                direction="column"
                                spacing={2}
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                Место проведения работ
                                Услуги



                                <Button variant="contained" onClick={handlerAddService}>
                                    Добавить услугу
                                </Button>

                                <TableData
                                    arrHead={["№", "Услуга", "Кол-во", "Стоимость", "Сумма", "Доп. инфо", "Действие"]}
                                    arrBody={services?.map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell size='small'>{index + 1}</TableCell>
                                                <ServicesData values={services}
                                                    handlerChange={handlerChangeServices}
                                                    data={data_GET_DATA_FOR_CREATE_CONTRACT?.getDataForCreateContract?.services}
                                                    index={index}
                                                />
                                                <TableCell size='small'>{<>
                                                    <IconButton aria-label="delete"
                                                        onClick={() => handlerRemoveService(item.service)}>
                                                        <DeleteIcon style={{ color: red[800] }}
                                                            fontSize='small' />
                                                    </IconButton>
                                                </>}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                />
                            </Stack>

                        </>
                    </DialogContent>

                    <DialogActions>
                        <Button variant="contained" onClick={handleClose}>
                            Отмена
                        </Button>
                        <Button color='primary' variant="contained" type="submit">
                            Сохранить
                        </Button>
                    </DialogActions>

                </form>

            </Dialog>
        </>
    )

};


export default CreateContract;