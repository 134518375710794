import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Tooltip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';


function PaperComponent(props) {
    const nodeRef = React.useRef(null);
    return (
      <Draggable
        nodeRef={nodeRef}
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} ref={nodeRef} />
      </Draggable>
    );
  }


const OpenLazyQuery = ({ title, tButtonIcon, tButtonText, dialogTitle, icon, Fields, fullScreen, fullWidth, maxWidth,
    dialogActions, onChange, state, headActions }) => {

    const [open, setOpen] = useState(state)

    const handleClose = () => {
        setOpen(false)
        onChange()
    };

    return (
        <>
            <Tooltip title={title} arrow>

                <IconButton style={tButtonIcon ? { width: 5, height: 5 } : null} onClick={onChange}>
                    {!tButtonIcon
                        ? <OpenInNewIcon fontSize="small" color='primary'
                        // style={{ color: green[800] }} 
                        />
                        : <>
                            {icon ? icon
                                : <Box fontSize={11}>
                                    {tButtonText}
                                </Box>}
                        </>}
                </IconButton>
            </Tooltip>

            <Dialog PaperComponent={PaperComponent}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={state} onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ 
                    // backgroundColor: lime[200], 
                    cursor: 'move' }}>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} alignItems="center">
                            {dialogTitle}
                        </Box>
                        {!headActions ? null
                            : <Box>
                                {headActions}
                            </Box>}
                        <Box>
                            <Tooltip title={"Закрыть"} arrow>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon color='primary'/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </DialogTitle>

                <DialogContent 
                // style={{ backgroundColor: green[50] }}
                >
                    {Fields}
                </DialogContent>

                {!dialogActions
                    ? null
                    : <DialogActions 
                    // style={{ backgroundColor: green[50] }}
                    >

                        <Button 
                        // style={{ backgroundColor: lime[200] }}
                            variant="contained"
                            type="Submit"
                            onClick={handleClose}
                            color="secondary">
                            Закрыть
                        </Button>
                    </DialogActions>}

            </Dialog>
        </>
    );


};


export default OpenLazyQuery;