import React from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';


export default function TableData({ arrHead, arrBody }) {

    return (
            <TableContainer component="div">
                <Table size="small" aria-label="a dense table">

                    <TableHead>
                        <TableRow>
                            {arrHead.map((m, index) =>
                                <TableCell key={index} size='small'>
                                    <Typography variant='caption'>{m}</Typography>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {arrBody}
                    </TableBody>

                </Table>
            </TableContainer>
    );


};