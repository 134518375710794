import React from 'react';
import { useQuery } from '@apollo/client';

import Loader from '../../../components/main/Loader';

import { GET_CONTRACTS } from '../../../apollo/gql/billing/Contract';
import CreateContract from './createContract';
import GetContracts from './getContracts';
import { Grid2 } from '@mui/material';


const Contracts = ({ context, currentUser }) => {

    const { loading, error, data } = useQuery(GET_CONTRACTS, {
        variables: {
            input: { _id: currentUser?.domain?.organization?._id }
        }
    });

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    return (
        data && <>
            <Grid2
                container
                direction="column"
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <CreateContract context={context} currentUser={currentUser} />
                <GetContracts
                    context={context}
                    data={data?.getContracts}
                />
            </Grid2>
        </>
    )

};


export default Contracts;