import React, { useContext } from 'react';
import { Route, Routes } from 'react-router';
import './app.css';
import { Grid2 } from '@mui/material';

import { AuthContext } from './context/authContext';
import { useCurrentUser } from './hooks/currentUser.hook';
import MainNavBar from './components/main/mainNavBar';

import { WebAppPage } from './pages/telegramWebApp/webAppPage';
import ContractsPage from './pages/contractsPage';
import NavbarTelegram from './components/main/navbarTelegram';


function App() {

  const context = useContext(AuthContext);
  let { currentUser } = useCurrentUser();
  console.log('context', context)
  console.log('currentUser', currentUser)
  return (
    <>
      {context && (context?.user?._id || context?.user?.user_id)
        ? <div className={"form"}>
        <NavbarTelegram />
        <Grid2 container spacing={2}>
          <Routes>
          <Route path="/contracts" element={<ContractsPage context={context} currentUser={currentUser}/>} />
            {/* <Route path="/" element={<WebAppPage />} /> */}
          </Routes>
        </Grid2>
        </div>
        : <>
        WebAppPage
        <Routes>
          <Route path="/" element={<WebAppPage />} />
        </Routes>
        </>
        }
    </>
  );


}


export default App;