import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { green } from '@mui/material/colors';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';


const Update = ({ title, tButtonIcon, tButtonText, fontSize, fullScreen, fullWidth, maxWidth, formSubmit, dialogTitle, Fields, icon, dialogActions }) => {

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip title={title}
                arrow>
                <IconButton style={tButtonIcon ? { width: 5, height: 5 } : null}
                    onClick={() => setOpen(true)}>
                    {!tButtonIcon
                        ? <EditIcon fontSize={fontSize}
                            style={{ color: green[800] }} />
                        : <>
                            {icon ? icon : <Box fontSize={11}>
                                {tButtonText}
                            </Box>}
                        </>}
                </IconButton>
            </Tooltip>

            <Dialog fullScreen={fullScreen}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">

                <form onSubmit={formSubmit}>
                    <DialogTitle id="form-dialog-title">
                        <Box display="flex" >
                            <Box p={0}
                                flexGrow={1}
                                textAlign="center"
                                alignItems="center">
                                {dialogTitle}
                            </Box>
                            <Box p={0}>
                                <Tooltip title={"Закрыть"}
                                    arrow>
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </DialogTitle>

                    <DialogContent>
                        {Fields}
                    </DialogContent>

                    {!dialogActions
                        ? null
                        : <DialogActions>
                            <Button type="Submit"
                                onClick={handleClose}
                                color="secondary">
                                Сохранить изменения
                            </Button>
                        </DialogActions>}
                </form>
            </Dialog>
        </>


    );


};


export default Update;