import { gql } from "@apollo/client";


export const AUTH_VIA_TELEGRAM = gql`
    mutation authViaTelegram($chatId: String) {
        authViaTelegram(chatId: $chatId) {
            _id,
            token,
            organization
        }
    }
`;