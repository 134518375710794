import React from 'react';

import { Divider, Stack, Typography } from '@mui/material';

import OpenDataId from './openDataId';


const SystemInfo = ({ data }) => {

  return (
    <Stack spacing={0} paddingTop={2} sx={{ width: '100%' }}>
      <Divider>
        <Typography variant='caption'>
          Системная информация
        </Typography>
      </Divider>
      <OpenDataId
        label={"ID"}
        data={data?._id}
      />
      <OpenDataId
        label={"Дата создания"}
        data={data?.createdAt}
      />
      <OpenDataId
        label={"Дата изменения"}
        data={data?.updatedAt}
      />
    </Stack>
  );

};


export default SystemInfo;